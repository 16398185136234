import React, { useEffect, useState } from "react";
import { InputError } from "../../commons/MicroComponents";
import { useDispatch, useSelector } from "react-redux";
// import { loginApiAdmin } from "../../redux/auth/slice";
import RippleLoader from "../../commons/RippleLoader";
import { Link, useNavigate } from "react-router-dom";
import { Eye, EyeSlash } from "iconsax-react";
import { Logo, SignUp } from "../../assets/image";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "./Auth.css";
import { Register } from "../../redux/auth/slice";
import { InputWithLabel } from "../../components/micro";
import { getCountryData } from "../../redux/address/slice";
import ReactSelect from "react-select";
import { getToken, messaging } from "../../commons/Firebase";
import { v4 as uuidv4 } from "uuid";

// Device Type
const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (/mobile/i.test(userAgent)) {
    return "Mobile";
  } else if (/tablet|ipad|playbook|silk|android(?!.*mobi)/i.test(userAgent)) {
    return "Tablet";
  }
  return "Desktop";
};

// Device Name
const getDeviceName = () => {
  const userAgent = navigator.userAgent;
  // Simple regex to match device names (customize this as needed)
  if (userAgent.match(/iPhone/i)) return "iPhone";
  if (userAgent.match(/iPad/i)) return "iPad";
  if (userAgent.match(/Android/i)) return "Android Device";
  if (userAgent.match(/Windows/i)) return "Windows PC";
  if (userAgent.match(/Mac/i)) return "Mac";
  return "Unknown Device";
};

const getDeviceID = () => {
  let deviceID = localStorage.getItem("device_id");
  if (!deviceID) {
    deviceID = uuidv4();
    localStorage.setItem("device_id", deviceID);
  }
  return deviceID;
};

function Auth({ selectedCountry }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loginState, setLoginState] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [countryArray, setCountryArray] = useState([]);
  const [fcmToken, setFcmToken] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [deviceID, setDeviceID] = useState("");

  const { countryData, countryLoading } = useSelector((store) => ({
    // Country Data
    countryData: store?.addressData?.getCountryDataStatus?.data,
    countryLoading: store?.addressData?.getCountryDataStatus?.loading,
  }));

  useEffect(() => {
    dispatch(getCountryData());
    setDeviceType(getDeviceType());
    setDeviceName(getDeviceName());
    setDeviceID(getDeviceID());
  }, [dispatch]);

  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({ ...x, label: x.phonecode }));
      setCountryArray(data);
    }
  }, [countryData]);

  const Vapid_key = process.env.VITE_APP_VAPID_KEY;

  useEffect(() => {
    const requestPermission = async () => {
      try {
        await Notification.requestPermission();
        if (Notification.permission === "granted") {
          const token = await getToken(messaging, {
            vapidKey: Vapid_key,
          });
          setFcmToken(token);
        } else {
          console.log("Unable to get permission to notify.");
        }
      } catch (err) {
        console.log("Error while requesting permission ", err);
      }
    };

    requestPermission();
  }, []);

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Please Enter your Full Name"),
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Please Enter your Email"),
    password: Yup.string()
      .required("Please Enter your New Password")
      .min(6, "Password must be at least 6 characters long"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must Match")
      .required("Please Confirm your New Password"),
    country: Yup.object().required("Please Select Country"),
    phone: Yup.string()
      .required("Please Enter Mobile")
      .max(10, "Mobile is too long - should be 10 chars maximum."),
      dob: Yup.date()
      .nullable()
      .required("Please Select Date Of Birth")
      .test('age', 'You must be at least 18 years old', value => {
        const today = new Date();
        const birthDate = new Date(value);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age >= 18;
      }),
    gender: Yup.string().required("Please Select your Gender"),
  });

  const handleRegister = (values) => {
    dispatch(
      Register({
        fcm_token: fcmToken,
        device_type: deviceType,
        device_name: deviceName,
        device_id: deviceID,
        ...values,
      })
    ).then((res) => {
      setLoginState(false);
      if (res.type === "Register/fulfilled") {
        navigate("/verification-email");
      }
    });
  };

  return (
    <>
      <div className="main snap-x">
        {loginState && <RippleLoader />}
        <div className="center-h">
          <div className="flex w-full flex-col sm:items-center lg:flex-row">
            {/* sm:text-nowrap text-wrap */}
            <section className="w-10">
              <div className="absolute top-3 left-6 w-20 h-20">
                <img
                  src={Logo}
                  alt="logo"
                  className="w-full h-full object-cover"
                />
              </div>
            </section>
            <section className="w-full sm:w-1/2 lg:w-1/2 xl:w-1/2">
              <div className="max-w-xl mx-auto">
                <div className="main-view ">
                  <div className={"mb-8"}>
                    <p className="text-sm opacity-65 mb-2">Welcome!</p>
                    <h1 className="sm:text-2xl text-lg font-ebGaramond font-semibold mb-1">
                      Sign Up Now
                    </h1>
                  </div>
                  {/* Login flow */}
                  <Formik
                    initialValues={{
                      full_name: "",
                      email: "",
                      password: "",
                      confirm_password: "",
                      country: "",
                      phone: "",
                      dob: "",
                      gender: "",
                      role: "customer",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      handleRegister(values);
                      setLoginState(true);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="space-y-5">
                          {/* Full Name */}
                          <InputWithLabel
                            className={
                              errors.full_name && touched.full_name
                                ? ` input-error `
                                : ` border `
                            }
                            idFromName="full_name"
                            label="Full Name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.full_name}
                            errors={errors.full_name && touched.full_name}
                            errorsText={errors.full_name}
                          />
                          {/* EMail */}
                          <InputWithLabel
                            className={
                              errors.email && touched.email
                                ? ` input-error `
                                : ` border `
                            }
                            idFromName="email"
                            label="Email"
                            type="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            errors={errors.email && touched.email}
                            errorsText={errors.email}
                          />
                          {/* New Password */}
                          <InputWithLabel
                            className={
                              errors.password && touched.password
                                ? ` input-error `
                                : ` border `
                            }
                            idFromName="password"
                            label="New Password"
                            type={passwordType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            errors={errors.password && touched.password}
                            errorsText={errors.password}
                            icon={
                              <p
                                className="p-2 absolute top-1.5 right-0 rounded bg-transparent z-20"
                                onClick={() => {
                                  setPasswordType(
                                    passwordType === "password"
                                      ? "text"
                                      : "password"
                                  );
                                }}
                              >
                                {passwordType === "password" ? (
                                  <EyeSlash size="20" />
                                ) : (
                                  <Eye size="20" />
                                )}
                              </p>
                            }
                          />
                          {/* Confirm New Password */}
                          <InputWithLabel
                            className={
                              errors.confirm_password &&
                              touched.confirm_password
                                ? ` input-error `
                                : ` border `
                            }
                            idFromName="confirm_password"
                            label="Confirm New Password"
                            type={confirmPasswordType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirm_password}
                            errors={
                              errors.confirm_password &&
                              touched.confirm_password
                            }
                            errorsText={errors.confirm_password}
                            icon={
                              <p
                                className="p-2 absolute top-1.5 right-0 rounded bg-transparent z-20"
                                onClick={() => {
                                  setConfirmPasswordType(
                                    confirmPasswordType === "password"
                                      ? "text"
                                      : "password"
                                  );
                                }}
                              >
                                {confirmPasswordType === "password" ? (
                                  <EyeSlash size="20" />
                                ) : (
                                  <Eye size="20" />
                                )}
                              </p>
                            }
                          />
                          <div className="flex space-x-4  w-full">
                            {/* Country */}
                            <div className="w-1/3">
                              <div
                                className={`${
                                  errors.country && touched.country
                                    ? ` input-error `
                                    : ""
                                } relative bg-white transition duration-300 rounded`}
                              >
                                <ReactSelect
                                  className={"text-black p-0"}
                                  id="country"
                                  placeholder="Country"
                                  options={countryArray}
                                  isLoading={countryLoading}
                                  getOptionValue={(countryArray) =>
                                    countryArray.name
                                  }
                                  value={
                                    countryArray?.length > 0 &&
                                    countryArray?.find((op) => {
                                      return op.label === values.country.label;
                                    })
                                  }
                                  onChange={(e) => {
                                    setFieldValue("country", e);
                                    setFieldValue("countryId", e?.id);
                                  }}
                                  styles={{
                                    control: (base) => ({
                                      ...base,
                                      border: `1px solid ${
                                        errors.country && touched.country
                                          ? "red"
                                          : "#0000003B"
                                      }`,
                                      borderRadius: "0.25rem",
                                      padding: "5px 4px",
                                      boxShadow: "none",
                                      "&:hover": {
                                        border: "1px solid #0000003B",
                                      },
                                    }),
                                  }}
                                />
                                <label
                                  htmlFor="country"
                                  className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                                >
                                  Country
                                </label>
                              </div>
                              {errors.country && touched.country ? (
                                <>
                                  <InputError errorTitle={errors.country} />
                                </>
                              ) : null}
                            </div>
                            {/* Phone */}
                            <div className="w-2/3">
                              <InputWithLabel
                                className={
                                  errors.phone && touched.phone
                                    ? ` input-error `
                                    : ` border `
                                }
                                idFromName="phone"
                                label="Mobile Number"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                errors={errors.phone && touched.phone}
                                errorsText={errors.phone}
                              />
                            </div>
                          </div>
                          {/* Date of Birth */}
                          <div>
                            <div
                              className={`${
                                errors.dob && touched.dob
                                  ? ` input-error `
                                  : ` `
                              } relative bg-white transition duration-300 rounded plus-number-inputs`}
                            >
                              <DatePicker
                                selected={values.dob}
                                onChange={(date) =>
                                  setFieldValue("dob", new Date(date))
                                }
                                maxDate={new Date()}
                                id="dob"
                                dateFormat="dd/MM/yyyy"
                                placeholderText={"Select Date Of Birth"}
                                className={`border border-secondary_grey ${
                                  errors.dob && touched.dob ? "error-forms" : ""
                                } rounded w-full px-3 py-2.5`}
                              />
                              <label
                                htmlFor="dob"
                                className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                              >
                                Date Of Birth
                              </label>
                            </div>
                            {errors.dob && touched.dob ? (
                              <InputError errorTitle={errors.dob} />
                            ) : null}
                          </div>
                          {/* Gender */}
                          <div>
                            <label className="block">Gender</label>
                            <div className="inline-flex items-center">
                              <label
                                className="relative flex items-center p-2 rounded-full cursor-pointer"
                                htmlFor="male"
                                data-ripple-dark="true"
                              >
                                <input
                                  name="gender"
                                  type="radio"
                                  value="male"
                                  className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-blue-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                                  id="male"
                                  checked={values.gender === "male"}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="absolute text-blue-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3.5 w-3.5"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                  >
                                    <circle
                                      data-name="ellipse"
                                      cx="8"
                                      cy="8"
                                      r="8"
                                    ></circle>
                                  </svg>
                                </span>
                              </label>
                              <label
                                className="mt-px font-light text-gray-700 cursor-pointer select-none"
                                htmlFor="male"
                              >
                                Male
                              </label>
                            </div>
                            <div className="inline-flex items-center">
                              <label
                                className="relative flex items-center p-2 rounded-full cursor-pointer"
                                htmlFor="female"
                              >
                                <input
                                  name="gender"
                                  type="radio"
                                  value="female"
                                  className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-blue-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                                  id="female"
                                  checked={values.gender === "female"}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="absolute text-blue-500 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3.5 w-3.5"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                  >
                                    <circle
                                      data-name="ellipse"
                                      cx="8"
                                      cy="8"
                                      r="8"
                                    ></circle>
                                  </svg>
                                </span>
                              </label>
                              <label
                                className="mt-px font-light text-gray-700 cursor-pointer select-none"
                                htmlFor="female"
                              >
                                Female
                              </label>
                            </div>
                            {errors.gender && touched.gender ? (
                              <InputError errorTitle={errors.gender} />
                            ) : null}
                          </div>
                          {/*  Button */}
                          <button type="submit" className="btn">
                            REGISTER
                          </button>

                          <div className="flex text-sm">
                            <p className="mr-1 font-light">
                              Already have an Account ?
                            </p>
                            <Link
                              to="/login"
                              className="cursor-pointer font-bold"
                            >
                              Login Now
                            </Link>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </section>
            <section className="hidden sm:flex lg:flex xl:flex items-center">
              <img
                src={SignUp}
                alt="logo"
                className="sm:max-w-fit lg:max-w-screen-sm"
              />
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Auth;
