// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  companyExistStatus: { data: null, loading: false },
  businessActivitiesStatus: { data: null, loading: false },
  companyFundResourcesStatus: { data: null },
  AttractionsStatus: { data: null },
  AddressStatus: { data: null },
  createAddressStatus: { data: null },
  addDirectorShareholderStatus: { data: null, loading: false },
  deleteDirectorShareholderStatus: { data: null },
  existingUsersStatus: { data: null, loading: false },
  companyDataStatus: { data: null },
  CreateCompanyStatus: { data: null },
  getNomineeDirectorsDetailsStatus: { data: null },
  getCompanySubscriptionSummaryStatus: { data: null },
  employmentSupportStatus: { data: null },
  employmentPassStatus: { data: null },
  paymentSummaryStatus: { data: null },
  makePaymentStatus: { data: null },
  getCorporateSecretaryStatus: { data: null },
  getCompanyStatus: {data: null},
};
