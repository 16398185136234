import React, { useState } from "react";
import DebounceSearch from "../../commons/DebounceSearch";
import ReactSelect from "react-select";
import { Plus } from "../../assets/svg/AllSvg";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../commons/Breadcrumb";
import AllTickets from "./AllTickets";
import TicketModal from "../modal/AddTickets";

export default function Tickets() {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("tickets");
  const [searchValue, setSearchValue] = useState("");
  const [selectedTagStatus, setSelectedTagStatus] = useState();
  const [selectedKyc, setSelectedKyc] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Tag Status Dropdown
  const tagStatusDropdown = [
    { value: "High Priority", label: "High Priority" },
    { value: "Mid Priority", label: "Mid Priority" },
    { value: "Low Priority", label: "Low Priority" },
  ];

  //   KYC Dropdown
  const kycDropdown = [
    { value: "Today", label: "Today" },
    { value: "This Week", label: "This Week" },
    { value: "This Month", label: "This Month" },
    { value: "All", label: "All" },
  ];

  return (
    <>
      <div className="mb-4">
        <h6 className="font-ebGaramond font-normal text-xl">Tickets</h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"Tickets"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="card border p-6 shadow-lg bg-white h-auto">
        <div className="flex justify-between items-start mb-6">
          <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
            EI Biz Tickets
          </h6>

          <button
            className="btn-theme flex items-center uppercase"
            onClick={openModal}
          >
            <Plus className="mr-2" /> Add Tickets
          </button>
          <TicketModal isOpen={isModalOpen} onClose={closeModal} />
        </div>

        <div className="flex justify-between items-center">
          <div>
            <DebounceSearch
              search={searchValue}
              handleChange={setSearchValue}
              placeholder={"Search..."}
            />
          </div>
          <div className="flex space-x-3">
            <ReactSelect
              className={"w-40 max-w-xs text-black p-0"}
              id="tagStatusDropdown"
              placeholder="Priority"
              options={tagStatusDropdown}
              isLoading={false}
              getOptionValue={(selectedTagStatus) => selectedTagStatus.value}
              value={selectedTagStatus}
              onChange={(e) => {
                setSelectedTagStatus(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
            <ReactSelect
              className={"w-40 max-w-xs text-black p-0"}
              id="kyc"
              placeholder="This Week"
              options={kycDropdown}
              isLoading={false}
              getOptionValue={(selectedKyc) => selectedKyc.value}
              value={selectedKyc}
              onChange={(e) => {
                setSelectedKyc(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
          </div>
        </div>

        <div className="border-b border-gray-200 dark:border-gray-700">
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <button
                onClick={() => handleTabClick("tickets")}
                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                  activeTab === "tickets"
                    ? "text-themeBlue border-themeBlue"
                    : "border-transparent"
                }`}
                role="tab"
                aria-selected={activeTab === "tickets"}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_5283_207108)">
                    <path
                      d="M20 6H10V12H8V4H14V0H6V6H4C2.9 6 2 6.9 2 8V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V8C22 6.9 21.1 6 20 6Z"
                      fill={activeTab === "tickets" ? "#002576" : "#9ca3af"}
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5283_207108">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p className="ml-2 text-center">All Tickets</p>
                <span className="text-center ms-3 text-xs font-medium text-themeBlue font-poppins">
                  16
                </span>
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                onClick={() => handleTabClick("new")}
                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                  activeTab === "new"
                    ? "text-themeBlue border-themeBlue"
                    : "border-transparent"
                }`}
                role="tab"
                aria-selected={activeTab === "new"}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 8.98V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4H14.1C14.04 4.32 14 4.66 14 5C14 6.48 14.65 7.79 15.67 8.71L12 11L4 6V8L12 13L17.3 9.68C17.84 9.88 18.4 10 19 10C20.13 10 21.16 9.61 22 8.98ZM16 5C16 6.66 17.34 8 19 8C20.66 8 22 6.66 22 5C22 3.34 20.66 2 19 2C17.34 2 16 3.34 16 5Z"
                    fill={activeTab === "new" ? "#002576" : "#9ca3af"}
                  />
                </svg>

                <p className="ml-2">New</p>
                <span className="text-center ms-3 text-xs font-medium text-themeBlue font-poppins">
                  1
                </span>
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                onClick={() => handleTabClick("onGoing")}
                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                  activeTab === "onGoing"
                    ? "text-themeBlue border-themeBlue"
                    : "border-transparent"
                }`}
                role="tab"
                aria-selected={activeTab === "onGoing"}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                    fill={activeTab === "onGoing" ? "#002576" : "#9ca3af"}
                  />
                </svg>

                <p className="ml-2">On-Going</p>
                <span className="text-center ms-3 text-xs font-medium text-themeBlue font-poppins">
                  10
                </span>
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                onClick={() => handleTabClick("resolved")}
                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                  activeTab === "resolved"
                    ? "text-themeBlue border-themeBlue"
                    : "border-transparent"
                }`}
                role="tab"
                aria-selected={activeTab === "resolved"}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 19C12 15.13 15.13 12 19 12C20.08 12 21.09 12.25 22 12.68V6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H12.08C12.03 19.67 12 19.34 12 19ZM4 6L12 11L20 6V8L12 13L4 8V6ZM17.34 22L13.8 18.46L15.21 17.05L17.33 19.17L21.57 14.93L23 16.34L17.34 22Z"
                    fill={activeTab === "resolved" ? "#002576" : "#9ca3af"}
                  />
                </svg>

                <p className="ml-2">Resolved</p>
                <span className="text-center ms-3 text-xs font-medium text-themeBlue font-poppins">
                  5
                </span>
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                onClick={() => handleTabClick("archived")}
                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${
                  activeTab === "archived"
                    ? "text-themeBlue border-themeBlue"
                    : "border-transparent"
                }`}
                role="tab"
                aria-selected={activeTab === "archived"}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.54 5.23L19.15 3.55C18.88 3.21 18.47 3 18 3H6C5.53 3 5.12 3.21 4.84 3.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V6.5C21 6.02 20.83 5.57 20.54 5.23ZM12 17.5L6.5 12H10V10H14V12H17.5L12 17.5ZM5.12 5L5.93 4H17.93L18.87 5H5.12Z"
                    fill={activeTab === "archived" ? "#002576" : "#9ca3af"}
                  />
                </svg>

                <p className="ml-2">Archived</p>
                <span className="text-center ms-3 text-xs font-medium text-themeBlue font-poppins">
                  5
                </span>
              </button>
            </li>
          </ul>
        </div>
        <div>
          {activeTab === "tickets" && (
            <AllTickets replyModal={openModal} />
          )}
          {activeTab === "new" && <AllTickets replyModal={openModal} />}
          {activeTab === "onGoing" && (
            <AllTickets replyModal={openModal} />
          )}
          {activeTab === "resolved" && (
            <AllTickets replyModal={openModal} />
          )}
          {activeTab === "archived" && (
            <AllTickets replyModal={openModal} />
          )}
        </div>
      </div>
    </>
  );
}
