import React from "react";
import { BackButton } from "../../../components/micro";
import { headChat } from "../../../assets/image";
import { useNavigate } from "react-router-dom";

const CloseBankAccount = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="space-y-5">
        <BackButton
          onClick={() => {
            navigate("/service-request");
          }}
        />
        <div className="flex items-center space-x-5 border-b-2 border-secondary_grey pb-7">
          <img src={headChat} alt="img" />
          <div className="space-y-2 font-ebGaramond">
            <p className="text-xl">We see that you will need a request on:</p>
            <p className="text-3xl">Close Bank Account</p>
          </div>
        </div>
        <div className="space-y-3 border-b-2 border-secondary_grey pb-4">
          <p className="text-2xl font-ebGaramond">
            Let us guide u though the process:
          </p>
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>1.</span>
            <p>EiBIZ will create relevant documents</p>
          </div>
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>2.</span>
            <p>Signed the relevant doucments</p>
          </div>
          <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
            <span>3.</span>
            <p>Bring signed banks closure resolution to bank for closure</p>
          </div>
          <p className="text-sm">
            Once the request is opened. You can easily manage it over at
            “On-Going” service page.
          </p>
        </div>
        <div className="flex items-center justify-between space-x-1  bg-light_primary_shades rounded-lg px-4 py-3">
          <p className="font-semibold">Total Payable :</p>
          <div>
            <p className="text-xs">1/3 used</p>
            <p>Free</p>
          </div>
        </div>
        <div className="box !rounded-xl flex items-center justify-between">
          <button
            className={`btn-theme uppercase`}
            onClick={() => {
              navigate("/approver");
            }}
          >
            Proceed with request
          </button>
          <div>
            <p className="text-xs">Total:</p>
            <p>Free</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CloseBankAccount;
