import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Company Exist
export const companyExist = createAsyncThunk(
  "companyExist",
  async (companyName, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`company-exist?name=${companyName}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Business Activities
export const businessActivities = createAsyncThunk(
  "businessActivities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`business-activities`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Company Data
export const companyData = createAsyncThunk(
  "companyData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`company-data`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Company
export const CreateCompany = createAsyncThunk(
  "CreateCompany",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`companies`, data);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Address
export const Address = createAsyncThunk(
  "Address",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`registered_address`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);
// Create Address
export const createAddress = createAsyncThunk(
  "createAddress",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`address`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);
// Existing Users
export const existingUsers = createAsyncThunk(
  "existingUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`existing-users`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Director / Shareholder
export const addDirectorShareholder = createAsyncThunk(
  "addDirectorShareholder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`users`, data);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// employment pass
export const employmentPass = createAsyncThunk(
  "employmentPass",
  async (_, { rejectWithValue }) => {
    try {
      // const response = await AxiosApi.get(`master-settings?slug=${slug}`);
      const response = await AxiosApi.get(`employment-details`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);
// Employment Support
export const employmentSupport = createAsyncThunk(
  "employmentSupport",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`companies/${id}`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);
// company payment summary
export const paymentSummary = createAsyncThunk(
  "paymentSummary",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`company-payment-summary/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);
// make payment
export const makePayment = createAsyncThunk(
  "makePayment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`make-payment`, data);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);

// Delete Director / Shareholder
export const DeleteDirectorShareholder = createAsyncThunk(
  "DeleteDirectorShareholder",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`users/${id}`);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Nominee Directors Details
export const getNomineeDirectorsDetails = createAsyncThunk(
  "getNomineeDirectorsDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `master-settings?slug=nominee_director`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Get Company Subscription Summary
export const getCompanySubscriptionSummary = createAsyncThunk(
  "getCompanySubscriptionSummary",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`company-subscription-summary/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Corporate Secretary
export const getCorporateSecretary = createAsyncThunk(
  "corporate_secretary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `master-settings?slug=corporate_secretary`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Company
export const getCompany = createAsyncThunk(
  "getCompany",
  async (page, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`companies?page=${page}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const companiesSlice = createSlice({
  name: "companies",
  initialState: initialStates,
  extraReducers: {
    // Company Exist
    [companyExist.pending]: (state) => {
      state.companyExistStatus.loading = true;
    },
    [companyExist.fulfilled]: (state, action) => {
      state.companyExistStatus.loading = false;
      state.companyExistStatus.data = action.payload;
      state.error = false;
    },
    [companyExist.rejected]: (state) => {
      state.companyExistStatus.loading = false;
      state.error = true;
    },
    // Business Activities
    [businessActivities.pending]: (state) => {
      state.businessActivitiesStatus.loading = true;
    },
    [businessActivities.fulfilled]: (state, action) => {
      state.businessActivitiesStatus.loading = false;
      state.businessActivitiesStatus.data = action.payload;
      state.error = false;
    },
    [businessActivities.rejected]: (state) => {
      state.businessActivitiesStatus.loading = false;
      state.error = true;
    },
    // Company Data
    [companyData.pending]: (state) => {
      state.loading = true;
    },
    [companyData.fulfilled]: (state, action) => {
      state.loading = false;
      state.companyDataStatus.data = action.payload;
      state.error = false;
    },
    [companyData.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Create Company
    [CreateCompany.pending]: (state) => {
      state.loading = true;
    },
    [CreateCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.CreateCompanyStatus.data = action.payload;
      state.error = false;
    },
    [CreateCompany.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Address
    [Address.pending]: (state) => {
      state.loading = true;
    },
    [Address.fulfilled]: (state, action) => {
      state.loading = false;
      state.AddressStatus.data = action.payload;
      state.error = false;
    },
    [Address.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Create Company
    [CreateCompany.pending]: (state) => {
      state.loading = true;
    },
    [CreateCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.CreateCompanyStatus.data = action.payload;
      state.error = false;
    },
    [CreateCompany.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Add Director Shareholder
    [addDirectorShareholder.pending]: (state) => {
      state.addDirectorShareholderStatus.loading = true;
    },
    [addDirectorShareholder.fulfilled]: (state, action) => {
      state.addDirectorShareholderStatus.loading = false;
      state.addDirectorShareholderStatus.data = action.payload;
      state.error = false;
    },
    [addDirectorShareholder.rejected]: (state) => {
      state.addDirectorShareholderStatus.loading = false;
      state.error = true;
    },
    // Existing Users
    [existingUsers.pending]: (state) => {
      state.existingUsersStatus.loading = true;
    },
    [existingUsers.fulfilled]: (state, action) => {
      state.existingUsersStatus.loading = false;
      state.existingUsersStatus.data = action.payload;
      state.error = false;
    },
    [existingUsers.rejected]: (state) => {
      state.existingUsersStatus.loading = false;
      state.error = true;
    },
    // employment Pass
    [employmentPass.pending]: (state) => {
      state.loading = true;
    },
    [employmentPass.fulfilled]: (state, action) => {
      state.loading = false;
      state.employmentPassStatus.data = action.payload;
      state.error = false;
    },
    [employmentPass.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // employment Support
    [employmentSupport.pending]: (state) => {
      state.loading = true;
    },
    [employmentSupport.fulfilled]: (state, action) => {
      state.loading = false;
      state.employmentSupportStatus.data = action.payload;
      state.error = false;
    },
    [employmentSupport.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // paymentASummary
    [paymentSummary.pending]: (state) => {
      state.loading = true;
    },
    [paymentSummary.fulfilled]: (state, action) => {
      state.loading = false;
      state.paymentSummaryStatus.data = action.payload;
      state.error = false;
    },
    [paymentSummary.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Delete Director Shareholder
    [DeleteDirectorShareholder.pending]: (state) => {
      state.loading = true;
    },
    [DeleteDirectorShareholder.fulfilled]: (state, action) => {
      state.loading = false;
      state.deleteDirectorShareholderStatus.data = action.payload;
      state.error = false;
    },
    [DeleteDirectorShareholder.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Get Nominee Directors Details
    [getNomineeDirectorsDetails.pending]: (state) => {
      state.loading = true;
    },
    [getNomineeDirectorsDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.getNomineeDirectorsDetailsStatus.data = action.payload;
      state.error = false;
    },
    [getNomineeDirectorsDetails.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Get Company Subscription Summary
    [getCompanySubscriptionSummary.pending]: (state) => {
      state.loading = true;
    },
    [getCompanySubscriptionSummary.fulfilled]: (state, action) => {
      state.loading = false;
      state.getCompanySubscriptionSummaryStatus.data = action.payload;
      state.error = false;
    },
    [getCompanySubscriptionSummary.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Make Payment
    [makePayment.pending]: (state) => {
      state.loading = true;
    },
    [makePayment.fulfilled]: (state, action) => {
      state.loading = false;
      state.makePaymentStatus.data = action.payload;
      state.error = false;
    },
    [makePayment.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // get Corporate Secretary Details
    [getCorporateSecretary.pending]: (state) => {
      state.loading = true;
    },
    [getCorporateSecretary.fulfilled]: (state, action) => {
      state.loading = false;
      state.getCorporateSecretaryStatus.data = action.payload;
      state.error = false;
    },
    [getCorporateSecretary.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // get Company
    [getCompany.pending]: (state) => {
      state.loading = true;
    },
    [getCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.getCompanyStatus.data = action.payload;
      state.error = false;
    },
    [getCompany.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});
const { reducer } = companiesSlice;
export default reducer;
