import React, { useEffect } from "react";
import { CheckBox, TextareaWithLabel } from "../../../components/micro";
import { useFormik } from "formik";

const BusinessManages = ({
  setCompanyCryptoActivitiesSelectedArray,
  setBusinessManagesError,
}) => {
  const BusinessManagesType = ["trading", "mining", "others"];

  const getInitialValues = (arr) => {
    let initial = {
      company_crypto_activities_other: "",
    };

    arr?.map((element) => {
      initial[element] = false;
    });
    return initial;
  };

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(BusinessManagesType),
  });

  useEffect(() => {
    let trueFieldKeys = Object.keys(formik.values).filter(
      (key) => formik.values[key]
    );
    // remove company crypto activities other form
    trueFieldKeys = trueFieldKeys.filter(function (item) {
      return item !== "company_crypto_activities_other";
    });
    setCompanyCryptoActivitiesSelectedArray({
      companyCryptoActivitiesSelectedArray: trueFieldKeys,
      company_crypto_activities_other:
        formik.values.company_crypto_activities_other,
    });
    setBusinessManagesError("");
  }, [formik.values]);
  return (
    <>
      {BusinessManagesType?.map((element) => {
        return (
          <CheckBox
            idFrom={element}
            label={element}
            name={element}
            value={formik.values[element]}
            checked={formik.values[element]}
            onChange={formik.handleChange}
            labelClass="capitalize"
          />
        );
      })}
      {formik.values.others && (
        <TextareaWithLabel
          idFromName={"company_crypto_activities_other"}
          label={"Others"}
          className={"border"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.company_crypto_activities_other}
        />
      )}
    </>
  );
};

export default BusinessManages;
