import React, { useContext, useState, useEffect } from "react";
import { down, cart, up, goBack } from "../../assets/image";
import { useNavigate } from "react-router-dom";
import CompanyContext from "./CompanyContext";
import { paymentSummary } from "../../redux/companies/slice";
import { useDispatch, useSelector } from "react-redux";

const Payment = ({ handleStepClick, companyId }) => {
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);
  const { prev, next } = useContext(CompanyContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paymentSummary(companyId));
  }, [dispatch]);

  const { paymentSummaryData } = useSelector((store) => ({
    paymentSummaryData: store?.companiesData?.paymentSummaryStatus?.data?.data,
  }));

  const handleFormSubmit = () => {
    navigate("/payment-last", {
      state: {
        companyId: companyId,
      },
    });
    if (handleStepClick) {
      next();
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const data = Array.from({ length: 30 }, (_, i) => ({
    name: `${i + 1} Shareholder`,
  }));
  return (
    <>
      <div>
        <div className="card border p-4 shadow-lg bg-white rounded-lg">
          <button
            className={
              "bg-white hover:bg-grey-700 text-theme font-bold rounded flex"
            }
            onClick={() => prev()}
          >
            <img className="w-5 h-5" src={goBack} />
            <span className="ml-2 font-poppins font-normal text-sm">BACK</span>
          </button>
          <div className="p-2">
            <p className="font-ebGaramond font-normal text-lg">
              Base on what we had gathered
            </p>
            <p className="font-ebGaramond font-normal text-xl">
              Here’s the summary and breakdown
            </p>
          </div>
          <p className="border" />

          <div className="mt-2 w-full flex justify-between">
            <div className="bg-bgPrimary p-3 rounded-md flex justify-between w-2/3">
              <p className="font-poppins font-normal text-sm">Total Payable:</p>
              <p className="font-poppins font-normal text-sm">
                ${paymentSummaryData?.total_payable}
              </p>
            </div>
            <div className="w-1/4 h-5">
              <button
                className="border rounded-3xl p-2 flex items-center shadow-lg bg-gray-200 w-32"
                onClick={toggleExpand}
              >
                <img
                  className="mr-1"
                  src={isExpanded ? up : down}
                  alt="Arrow"
                />
                <p className="font-poppins font-normal text-sm">
                  {isExpanded ? "View Less" : "View More"}
                </p>
              </button>
            </div>
          </div>
          <p className="border mt-3" />

          <div className="mt-2">
            <div className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full">
              <p className="font-ebGaramond font-normal text-xl">
                1. Company Incorporation
              </p>
              <div>
                <p className="font-poppins font-light text-xs">one time fees</p>
                <p className="font-poppins font-normal text-xs">
                  {" "}
                  ${paymentSummaryData?.incorporation_fees}
                </p>
              </div>
            </div>
            {isExpanded && (
              <>
                <button
                  className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full mt-2"
                  onClick={toggleExpand}
                >
                  <p className="font-poppins font-light text-base">
                    i. Registration filing and name application
                  </p>
                  <img
                    className="mr-1"
                    src={isExpanded ? up : down}
                    alt="Arrow"
                  />
                </button>
                <div className="p-2">
                  <ul className="text-xs font-normal list-inside text-themeBlue list-disc">
                    <li>Checking and reserving company name</li>
                    <li>Preparing all registration forms</li>
                    <li>
                      Preparing your company constitution (memorandum & articles
                      of the company)
                    </li>
                    <li>
                      Compiling compliance corporate kit, including share
                      certificates, registers, and minutes book
                    </li>
                    <li>Assisting with opening a bank/business account</li>
                  </ul>
                </div>
              </>
            )}
            <div className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full mt-2">
              <p className="ont-poppins font-light text-base">
                ii. Government Registration and Name Application Fees
              </p>
              <div>
                <p className="font-poppins font-light text-xs">one time fees</p>
                <p className="font-poppins font-normal text-xs">
                  ${paymentSummaryData?.govt_fees}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-2">
            <div className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full">
              <p className="font-ebGaramond font-normal text-xl">
                2. Corporate Secretary Package
              </p>
              <div>
                <p className="font-poppins font-light text-xs">Per Year</p>
                <p className="font-poppins font-normal text-xs">
                  ${paymentSummaryData?.corporate_secretary_fees}
                </p>
              </div>
            </div>
            {isExpanded && (
              <>
                <button
                  className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full mt-2"
                  onClick={toggleExpand}
                >
                  <p className="font-poppins font-light text-base">
                    i. This service offers access to common company secretary
                    services
                  </p>
                  <img
                    className="mr-1"
                    src={isExpanded ? up : down}
                    alt="Arrow"
                  />
                </button>
                <div className="p-2">
                  <ul className="text-xs font-normal list-inside text-themeBlue list-disc">
                    <li>Preparation & holding of AGM </li>
                    <li>Preparation & filing of Annual Return</li>
                    <li>Declaration and distribution of dividends</li>
                    <li>Many more ACRA transactions at no extra cost.</li>
                  </ul>
                  <div className="w-full pt-2">
                    <div className="relative border field">
                      <label
                        htmlFor="name"
                        className={
                          "label text-sm peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 text-light_grey"
                        }
                      >
                        Shareholder
                      </label>
                      <select
                        id="data"
                        name="data"
                        defaultValue=""
                        className="auth-input error-forms border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      >
                        <option value="" disabled>
                          {paymentSummaryData?.shareholders_count} Shareholder
                          {paymentSummaryData?.shareholders_count > 1
                            ? "s"
                            : ""}
                        </option>
                        {data.map((item) => (
                          <option key={item.name} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="font-poppins font-light text-xs">
                      Psst* Make sure you had selected the correct numbers of
                      Shareholder
                    </p>
                  </div>
                </div>
              </>
            )}
            <div className="bg-lightPrimary2 p-2 rounded-md flex justify-between w-full mt-2">
              <p className="ont-poppins font-light text-base">
                ii. Annual Filing Fee
              </p>
              <div>
                <p className="font-poppins font-light text-xs">Per Year</p>
                <p className="font-poppins font-normal text-xs">
                  ${paymentSummaryData?.annual_filing_fees}
                </p>
              </div>
            </div>
          </div>

          <p className="border mt-3" />
        </div>
        {/* button */}
        <div className="card border p-4 shadow-lg bg-white mt-3 rounded-lg flex justify-between">
          <button
            className={
              "bg-theme hover:bg-grey-700 text-white font-bold py-2 px-4 rounded flex"
            }
            onClick={handleFormSubmit}
          >
            <span className="mr-2 font-poppins font-normal text-sm">
              Continue to check out
            </span>
            <img className="w-5 h-5" src={cart} />
          </button>
          <div>
            <p className="font-poppins font-light text-xs">Total:</p>
            <p className="font-poppins font-normal text-sm">
              ${paymentSummaryData?.total}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
