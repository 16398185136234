import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackButton, RadioButton } from "../../../components/micro";
import { headChat } from "../../../assets/image";
import ReactSelect from "react-select";

const AllotmentTransferNewShareholder = () => {
  const [allotment_transfer_type, setAllotment_transfer_type] = useState();
  const navigate = useNavigate();

  return (
    <div className="space-y-5">
      <BackButton
        onClick={() => {
          navigate("/service-request");
        }}
      />
      <div className="flex items-center space-x-5 border-b-2 border-secondary_grey pb-7">
        <img src={headChat} alt="img" />
        <div className="space-y-2 font-ebGaramond">
          <p className="text-xl">We see that you will need a request on:</p>
          <p className="text-3xl">
            Allotment/Transfer of Shares to Existing/New Shareholders
          </p>
        </div>
      </div>
      <div className="space-y-3 border-b-2 border-secondary_grey pb-3">
        <p className="text-2xl font-ebGaramond">
          Let us guide u though the process:
        </p>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>1.</span>
          <p>Let us know which changes you will need</p>
        </div>
        <p className="text-sm">Select the type of service you need</p>
        <ReactSelect
          className={"p-0"}
          id="allotment_transfer_type"
          placeholder="Allotment/Transfer Type"
          options={[
            { value: "allotment", label: "Allotment" },
            { value: "transfer", label: "Transfer" },
          ]}
          onChange={(e) => {
            setAllotment_transfer_type(e);
          }}
          isLoading={false}
          styles={{
            control: (base) => ({
              ...base,
              border: `1px solid #cdddeb`,
              borderRadius: "0.3rem",
              paddingTop: "2px",
              paddingBottom: "2px",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #cdddeb",
              },
            }),
          }}
        />
        {allotment_transfer_type?.value === "allotment" && (
          <>
            <div className="space-y-3">
              <p>Will there be new shareholder?</p>
              <RadioButton
                label={"No"}
                idFrom={"no"}
                name={"new_shareholder"}
              />
              <RadioButton
                label={"Yes"}
                idFrom={"yes"}
                name={"new_shareholder"}
              />
            </div>
            <div className="space-y-3">
              <p>Are your new shareholder individual or corporate</p>
              <RadioButton
                label={"Individual"}
                idFrom={"individual"}
                name={"type_shareholder"}
              />
              <RadioButton
                label={"Corporate"}
                idFrom={"corporate"}
                name={"type_shareholder"}
              />
            </div>
            <div className="space-y-3">
              <p>Are your new shareholder local or from overseas</p>
              <RadioButton label={"Local"} idFrom={"local"} name={"local"} />
              <RadioButton
                label={"Overseas"}
                idFrom={"overseas"}
                name={"local"}
              />
            </div>
          </>
        )}
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>2.</span>
          <p>Configure cap table</p>
        </div>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>3.</span>
          <p>EiBIZ preparing the relevant resolution</p>
        </div>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>4.</span>
          <p>Obtain signed resolution</p>
        </div>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>5.</span>
          <p>
            We will lodge the changes to ACRA for the change of Company Name
          </p>
        </div>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>6.</span>
          <p>(If Need) IRAS stamp duty payment</p>
        </div>
        <p className="text-sm">
          Once the request is opened. You can easily manage it over at
          “On-Going” service page.
        </p>
      </div>
      <div className="flex items-center justify-between space-x-1  bg-light_primary_shades rounded-lg px-4 py-3">
        <p className="font-semibold">Total Payable :</p>
        <div>
          <p className="text-xs">1/3 used</p>
          <p>Free</p>
        </div>
      </div>
      <div className="box !rounded-xl flex items-center justify-between">
        <button
          className={`btn-theme uppercase`}
          onClick={() => {
            navigate("/approver");
          }}
        >
          Proceed with request
        </button>
        <div>
          <p className="text-xs">Total:</p>
          <p>$100</p>
        </div>
      </div>
    </div>
  );
};

export default AllotmentTransferNewShareholder;
