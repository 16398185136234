import React from "react";

const ChatModal = ({ imageUrl, onClose }) => {
  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-screen-md w-full relative">
        <button className="absolute top-4 right-4 p-2" onClick={onClose}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="black"
              fillOpacity="0.54"
            />
          </svg>
        </button>
        <img
          src={imageUrl}
          alt="Selected image"
          className="w-full h-auto max-h-[70vh] object-contain"
        />
        <button
          className="absolute top-16 right-4 p-1 rounded-md bg-white hover:bg-gray-300 border border-black shadow-md"
          onClick={downloadImage}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 17.59L17.59 12L16.17 10.58L14 12.76V7H10V12.76L7.82996 10.59L6.40996 12L12 17.59ZM19 17V19H5V17H3V21H21V17H19Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ChatModal;
