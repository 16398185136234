import React from "react";

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-30"
        onClick={onClose}
        aria-hidden="true"
      ></div>
      <div className="bg-white rounded-lg shadow-lg p-6 z-10 flex flex-col items-center text-center h-44 w-[40vh]">
        <h2 className="text-2xl font-normal font-ebGaramond mb-4">
          Confirm Save
          <br />
          My Profile?
        </h2>
        <div className="flex justify-end mt-3">
          <button
            className="btn-cancel w-[12vh] uppercase text-center justify-center flex border border-theme p-2 mr-4 rounded-lg text-theme font-poppins text-sm hover:bg-theme hover:text-white transition duration-200"
            onClick={onClose}
            aria-label="Cancel"
          >
            Cancel
          </button>
          <button
            className="btn-theme w-[12vh] uppercase text-center justify-center flex rounded-lg font-poppins text-sm bg-theme text-white p-2 hover:bg-opacity-80 transition duration-200"
            onClick={onConfirm}
            aria-label="Confirm"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
