import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RippleLoader from "../../commons/RippleLoader";
import { Logo, login } from "../../assets/image";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputWithLabel } from "../../components/micro";
import { LoginAPI } from "../../redux/auth/slice";
import { getToken, messaging } from "../../commons/Firebase";

import { v4 as uuidv4 } from "uuid";

// Device Type
const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (/mobile/i.test(userAgent)) {
    return "Mobile";
  } else if (/tablet|ipad|playbook|silk|android(?!.*mobi)/i.test(userAgent)) {
    return "Tablet";
  }
  return "Desktop";
};

// Device Name
const getDeviceName = () => {
  const userAgent = navigator.userAgent;
  // Simple regex to match device names (customize this as needed)
  if (userAgent.match(/iPhone/i)) return "iPhone";
  if (userAgent.match(/iPad/i)) return "iPad";
  if (userAgent.match(/Android/i)) return "Android Device";
  if (userAgent.match(/Windows/i)) return "Windows PC";
  if (userAgent.match(/Mac/i)) return "Mac";
  return "Unknown Device";
};

const getDeviceID = () => {
  let deviceID = localStorage.getItem("device_id");
  if (!deviceID) {
    deviceID = uuidv4();
    localStorage.setItem("device_id", deviceID);
  }
  return deviceID;
};

function Login() {
  const [fcmToken, setFcmToken] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [deviceID, setDeviceID] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginLoading } = useSelector((store) => ({
    loginLoading: store?.authData?.loading,
  }));

  const Vapid_key = process.env.VITE_APP_VAPID_KEY;
  const getAccessToken = sessionStorage.getItem("accessToken");

  useEffect(() => {
    const requestPermission = async () => {
      try {
        await Notification.requestPermission();
        if (Notification.permission === "granted") {
          const token = await getToken(messaging, {
            vapidKey: Vapid_key,
          });
          setFcmToken(token);
        } else {
          console.log("Unable to get permission to notify.");
        }
      } catch (err) {
        console.log("Error while requesting permission ", err);
      }
    };

    requestPermission();
    setDeviceType(getDeviceType());
    setDeviceName(getDeviceName());
    setDeviceID(getDeviceID());
  }, []);

  useEffect(() => {
    if (getAccessToken) {
      navigate("/");
    }
  }, []);

  const handleLogin = (values) => {
    dispatch(
      LoginAPI({
        fcm_token: fcmToken,
        device_type: deviceType,
        device_name: deviceName,
        device_id: deviceID,
        ...values,
      })
    ).then((res) => {
      if (res.type === "LoginAPI/fulfilled") {
        const getCompanyList = JSON.parse(sessionStorage.getItem("companyList"));
        navigate("/", { state: { companyList: getCompanyList } });
      }
    });
  };

  return (
    <>
      <div className="main">
        {loginLoading ? (
          <RippleLoader />
        ) : (
          <div className="center-h">
            <div className="main-logo">
              <img
                src={Logo}
                alt="logo"
                className="w-full h-full object-cover"
              />
            </div>
            <section className="w-full">
              <div className="max-w-xl w-full mx-auto">
                <div className="main-view border">
                  <div className={"mb-8"}>
                    <p className="text-xs font-normal font-Poppins">Welcome!</p>
                    <h1 className="text-2xl font-ebGaramond font-normal mb-1">
                      Login to Ei Biz
                    </h1>
                  </div>
                  {/* Login flow */}
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      fcm_token: fcmToken,
                      role: "customer",
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string().required("Please Enter Email"),
                      password: Yup.string()
                        .required("Please Enter Password")
                        .min(
                          8,
                          "Password is too short - should be 8 chars minimum."
                        )
                        .matches(
                          /[a-zA-Z]/,
                          "Password can only contain Latin letters."
                        ),
                    })}
                    onSubmit={(values) => {
                      handleLogin(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="space-y-5">
                          {/* User Name Or Email */}
                          <InputWithLabel
                            className={
                              errors.email && touched.email
                                ? ` input-error `
                                : ` border `
                            }
                            idFromName="email"
                            label="Username or Email"
                            type="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            errors={errors.email && touched.email}
                            errorsText={errors.email}
                          />
                          <div className="space-y-2">
                            {/* password */}
                            <InputWithLabel
                              className={
                                errors.password && touched.password
                                  ? ` input-error `
                                  : ` border `
                              }
                              idFromName="password"
                              label="Password"
                              type="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              errors={errors.password && touched.password}
                              errorsText={errors.password}
                            />
                            {/* forget password */}
                            <Link
                              to={"/reset-password"}
                              className="border-b border-gray-400 inline-block"
                            >
                              Forget Password?
                            </Link>
                          </div>
                          <button type="submit" className="btn">
                            Log In
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div className="flex text-sm mt-5">
                    <p className="mr-1 font-light">Don't have an Account ?</p>
                    <Link to="/sign-up" className="cursor-pointer font-bold">
                      Register
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            <section className="sm:flex lg:flex xl:flex items-center w-full">
              <img
                src={login}
                alt="logo"
                className="sm:max-w-fit lg:max-w-screen-sm"
              />
            </section>
          </div>
        )}
      </div>
    </>
  );
}

export default Login;
