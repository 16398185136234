// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  loginStatus: {
    data: null,
  },
  registerStatus: {
    data: null,
  },
  updateProfileStatus: {
    data: null,
  },
  forgetPasswordStatus: {
    data: null,
  },
  getProfileDataStatus: {
    data: null,
  },
  newPasswordStatus: {
    data: null,
  },
};
