import React, { useEffect, useState } from "react";
import CompanyInfo from "./CompanyInfo";
import "./company.css";
import CompanyAddress from "./CompanyAddress";
import { StepperImg } from "../../assets/image";
import InviteCompany from "./InviteCompany";
import Accounting from "./Accounting";
import Payment from "./Payment";
import PaymentLast from "./PaymentLast";
import { CheckCircle } from "../../assets/svg/AllSvg";
import { Provider } from "./CompanyContext";
import { useLocation } from "react-router-dom";
import DirectorsShareholders from "./directorsShareholders/DirectorsShareholders";

export default function NewCompany() {
  const [currentStep, setCurrentStep] = useState(0);
  const [companyId, setCompanyId] = useState("");

  const location = useLocation();
  const type = location.state.companyType;

  const tabLabel = [
    { value: "company_information", label: "Company Information" },
    { value: "company_address", label: "Company Address" },
    { value: "directors_shareholders", label: "Directors & Shareholders" },
    { value: "employment_support", label: "Employment Support" },
    { value: "payment", label: "Payment" },
  ];

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };
  const renderStep = (step) => {
    switch (
      tabLabel[step]?.value // added optional chaining to prevent undefined access
    ) {
      case "company_information":
        return (
          <CompanyInfo
            handleStepClick={handleStepClick}
            companyId={companyId}
            setCompanyId={setCompanyId}
            type={type}
          />
        );
      case "company_address":
        return (
          <CompanyAddress
            handleStepClick={handleStepClick}
            companyId={companyId}
            setCompanyId={setCompanyId}
          />
        );
      case "directors_shareholders":
        return (
          // <InviteCompany
          //   handleStepClick={handleStepClick}
          //   companyId={companyId}
          //   setCompanyId={setCompanyId}
          // />
          <DirectorsShareholders />
        );
      case "employment_support":
        return (
          <Accounting
            handleStepClick={handleStepClick}
            companyId={companyId}
            setCompanyId={setCompanyId}
          />
        );
      case "payment":
        return (
          <Payment
            handleStepClick={handleStepClick}
            companyId={companyId}
            setCompanyId={setCompanyId}
          />
        );
      case "confirmation":
        return (
          <PaymentLast
            handleStepClick={handleStepClick}
            companyId={companyId}
            setCompanyId={setCompanyId}
          />
        );
      default:
        return null;
    }
  };

  const next = () => {
    if (currentStep < tabLabel.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="flex">
      {/* stepper */}
      <Provider value={{ next, prev }}>
        <div className="p-4">
          <img
            className="w-30 h-30 relative bottom-1 right-4"
            src={StepperImg}
          />
          {/* <ol className="relative text-gray-500 border-s border-gray-200 dark:text-gray-400">
            <li className="mb-14 ms-6" onClick={() => handleStepClick(1)}>
              <span
                className={`mainView ${
                  currentStep === 1 ? "dark:bg-blue-900" : "dark:bg-gray-500"
                }`}
              >
                1
              </span>
              <h3
                className={`stepFont ${
                  currentStep === 1 ? "text-blue-900" : "text-gray-500"
                }`}
              >
                Company Information
              </h3>
            </li>
            <li className="mb-14 ms-6" onClick={() => handleStepClick(2)}>
              <span
                className={`mainView ${
                  currentStep === 2 ? "dark:bg-blue-900" : "dark:bg-gray-500"
                }`}
              >
                2
              </span>
              <h3
                className={`stepFont ${
                  currentStep === 2 ? "text-blue-900" : "text-gray-500"
                }`}
              >
                Company Address
              </h3>
            </li>
            <li className="mb-14 ms-6" onClick={() => handleStepClick(3)}>
              <span
                className={`mainView ${
                  currentStep === 3 ? "dark:bg-blue-900" : "dark:bg-gray-500"
                }`}
              >
                3
              </span>
              <h3
                className={`font-normal leading-tight font-poppins text-sm mt-1 ${
                  currentStep === 3 ? "text-blue-900" : "text-gray-500"
                }`}
              >
                Directors & <br />
                Shareholders
              </h3>
            </li>
            <li className="mb-14 ms-6" onClick={() => handleStepClick(4)}>
              <span
                className={`mainView ${
                  currentStep === 4 ? "dark:bg-blue-900" : "dark:bg-gray-500"
                }`}
              >
                4
              </span>
              <h3
                className={`stepFont ${
                  currentStep === 4 ? "text-blue-900" : "text-gray-500"
                }`}
              >
                Employment
              </h3>
              <p
                className={`stepFont text-xs ${
                  currentStep === 4 ? "text-blue-900" : "text-gray-500"
                }`}
              >
                Support
              </p>
            </li>
            <li className="ms-6" onClick={() => handleStepClick(5)}>
              <span
                className={`mainView ${
                  currentStep === 5 ? "dark:bg-blue-900" : " dark:bg-gray-500"
                }`}
              >
                5
              </span>
              <h3
                className={`stepFont ${
                  currentStep === 5 ? "text-blue-900" : "text-gray-500"
                }`}
              >
                Payment
              </h3>
            </li>
          </ol> */}
          <div className="stepper space-y-10">
            {tabLabel?.map((step, index) => (
              <div key={index} className="flex items-center space-x-3">
                <span
                  className={`max-w-8 w-full h-8 rounded-full flex justify-center items-center bg-gray-400 text-white ${
                    index < currentStep ? "!bg-white text-green-700" : ""
                  } ${index === currentStep ? "!bg-theme" : ""}`}
                >
                  {index < currentStep ? (
                    <CheckCircle
                      className="w-8 h-8"
                      pathClass={"fill-green-700"}
                    />
                  ) : (
                    index + 1
                  )}
                </span>
                <p
                  className={` ${index < currentStep ? "text-green-700" : ""} ${
                    index === currentStep ? "font-semibold" : ""
                  }`}
                >
                  {step.label}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* stepper */}
        <div className="w-full">
          {renderStep(currentStep)}
          {/* {currentStep === 1 && <CompanyInfo handleStepClick={handleStepClick} />}
        {currentStep === 2 && (
          <CompanyAddress handleStepClick={handleStepClick} />
        )}
        {currentStep === 3 && (
          <InviteCompany handleStepClick={handleStepClick} />
        )}
        {currentStep === 4 && <Accounting handleStepClick={handleStepClick} />}
        {currentStep === 5 && <Payment handleStepClick={handleStepClick} />}
        {currentStep === 6 && <PaymentLast handleStepClick={handleStepClick} />} */}
        </div>
      </Provider>
    </div>
  );
}
