import React from "react";
import { unSuccess, left, goBack } from "../../assets/image";
import { Link, useNavigate } from "react-router-dom";

export default function CheckOutUnSuccess() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1)
  };

  return (
    <div className="w-2/4">
      <button
        className={
          "bg-white hover:bg-grey-700 text-theme font-bold rounded flex"
        }
        onClick={handleBackClick}
      >
        <img className="w-5 h-5" src={goBack} />
        <span className="ml-2 font-poppins font-normal text-sm">BACK</span>
      </button>
      <p className="font-ebGaramond text-base mt-3">Opps!</p>
      <p className="font-ebGaramond text-2xl leading-6">
        We encounter some issues. You can test using another payment method. Or
        you can reach out to us
      </p>
      <div className="flex justify-end">
        <img className="h-50 w-60" src={unSuccess} />
      </div>
      <p className="border border-gray-400" />
    </div>
  );
}
