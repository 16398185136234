import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Logo, Reset } from "../../assets/image";
import { forgetPassword } from "../../redux/auth/slice";
import { InputWithLabel } from "../../components/micro";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="main">
        <div className="main-logo">
          <img src={Logo} alt="logo" className="w-full h-full object-cover" />
        </div>
        <div className="center-h">
          <section className="w-full">
            <div className="max-w-xl w-full mx-auto">
              <div className="relative top-5 left-1/4 w-fit h-fit">
                <img
                  src={Reset}
                  alt="logo"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="main-view ">
                <div className={"mb-8"}>
                  <h1 className="sm:text-2xl text-2xl font-ebGaramond font-normal mb-1">
                    Reset Password
                  </h1>
                  <p className="text-sm opacity-65 mb-1 text-Poppins font-normal">
                    We will send you a link to reset
                  </p>
                  <p className="text-sm opacity-65 text-Poppins font-normal">
                    your password via email
                  </p>
                </div>
                <Formik
                  initialValues={{
                    email: "",
                    role: "admin",
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .email("Invalid email address")
                      .required("Please Enter Email"),
                  })}
                  onSubmit={(values) => {
                    setLoading(true);
                    dispatch(forgetPassword(values)).then((res) => {
                      setLoading(false);
                      if (res.type === "forgetPassword/fulfilled") {
                        navigate("/login");
                      }
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="space-y-5">
                        {/* Email */}
                        <InputWithLabel
                          className={
                            errors.email && touched.email
                              ? ` input-error `
                              : ` border `
                          }
                          idFromName="email"
                          label="Email"
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          errors={errors.email && touched.email}
                          errorsText={errors.email}
                        />
                        <button type="submit" className="btn">
                          {loading ? "Loading...." : "SEND ME THE RESET LINK"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
