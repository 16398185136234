import React, { useEffect, useRef, useState } from "react";
import { MoreMenu, MoreVert, PDFDoc } from "../assets/svg/AllSvg";

const PdfDocument = ({ documentName, uploadTime, cardClasses }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleDownload = (file) => {
    console.log('handleDownload');
    const { type, content, name } = file;
    let mimeType;
    let fileContent;

    switch (type) {
      case "text":
        mimeType = "text/plain";
        fileContent = content;
        break;
      case "pdf":
        mimeType = "application/pdf";
        fileContent = atob(content);
        break;
      case "image":
        mimeType = "image/png";
        fileContent = atob(content);
        break;
      default:
        console.error("Unsupported file type:", type);
        return;
    }

    const blob = new Blob([fileContent], { type: mimeType });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`card bg-light_secondary space-y-2 ${cardClasses}`}>
      <div className={"flex justify-between items-center"}>
        <div className="flex items-center">
          <PDFDoc className="mr-3" />
          <p>{documentName}</p>
        </div>
        <div className="relative inline-block">
          <button
            ref={buttonRef}
            className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 sticky w-10 h-10"
            onClick={handleDropdownToggle}
          >
         <MoreMenu />
          </button>
          {isDropdownOpen && (
            <div
              ref={dropdownRef}
              id="dropdownDots"
              className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-28 absolute left-16 bottom-1"
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100"
                aria-labelledby="dropdownMenuIconButton"
              >
                <li>
                  <button
                    onClick={handleDownload}
                    className="block px-4 py-2 text-black"
                  >
                    Download
                  </button>
                </li>
                {/* {files.map((file, index) => (
              <li key={index}>
                <button
                  onClick={() => handleDownload(file)}
                  className="block px-4 py-2 text-black hover:bg-gray-100"
                >
                  {file.name}  Download
                </button>
              </li>
            ))} */}
              </ul>
            </div>
          )}
        </div>
      </div>
      {uploadTime && (
        <p className="text-center text-light_grey font-semibold text-xs leading-normal">
          Uploaded on <span className="block">“{uploadTime}”</span>
        </p>
      )}
    </div>
  );
};

export default PdfDocument;
