import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputWithLabel } from "../../components/micro";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputError } from "../../commons/MicroComponents";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import {
  companyUsers,
  getOrganisationDetails,
} from "../../redux/organisation/slice";
import ConfirmationModel from "../../commons/ConfirmationModel";

const AddUser = ({ setAddUserPopup, userData, companyId }) => {
  const [value, setValue] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const dispatch = useDispatch();

  const userType = {
    label: userData?.type,
    value: userData?.type,
  };

  const handleConfirmCreateUser = () => {
    setIsLoad(true);
    if (userData?.id) {
      const payload = {
        company_id: value.id,
        id: userData?.id,
        full_name: value.full_name,
        email: value.email,
        type: value.type.value,
      };
      dispatch(companyUsers(payload)).then((res) => {
        setIsLoad(false);
        setShowConfirmation(false);
        if (res.type === "companyUsers/fulfilled") {
          dispatch(getOrganisationDetails(companyId));
          setAddUserPopup(false);
        }
      });
    } else {
      const payload = {
        company_id: value.id,
        full_name: value.full_name,
        email: value.email,
        type: value.type.value,
      };
      dispatch(companyUsers(payload)).then((res) => {
        setIsLoad(false);
        setShowConfirmation(false);
        if (res.type === "companyUsers/fulfilled") {
          dispatch(getOrganisationDetails(companyId));
          setAddUserPopup(false);
        }
      });
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      <div className="backdrop-blur-sm bg-black/40 flex items-center justify-center w-full fixed top-0 left-0 right-0 z-[99] mx-auto h-screen">
        <div className="bg-white drop-shadow-lg rounded-lg w-full max-w-xl min-h-36 py-5 px-6 mx-auto relative">
          <div>
            <h2>{userData?.id ? "Edit User" : "Add User"}</h2>
            <p className="font-poppins text-sm font-light">
              {userData?.id
                ? "Edit the user and their role here"
                : "Create User and set their role here"}
            </p>
          </div>
          <Formik
            initialValues={{
              id: companyId,
              full_name: userData?.user?.full_name,
              email: userData?.user?.email,
              type: userData?.id ? userType : "",
            }}
            validationSchema={Yup.object({
              full_name: Yup.string().required("Please Enter Full Name"),
              email: Yup.string()
                .email("Invalid email address")
                .required("Please Enter Email"),
              type: Yup.object().required("Please Select User Role"),
            })}
            onSubmit={(values) => {
              setValue(values);
              setShowConfirmation(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit} className="mt-4 space-y-5">
                {/* Full Name */}
                <InputWithLabel
                  className={
                    errors.full_name && touched.full_name
                      ? ` input-error `
                      : ` border `
                  }
                  idFromName="full_name"
                  label="Full Name"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.full_name}
                  errors={errors.full_name && touched.full_name}
                  errorsText={errors.full_name}
                />
                {/* Email */}
                <InputWithLabel
                  className={
                    errors.email && touched.email ? ` input-error ` : ` border `
                  }
                  idFromName="email"
                  label="Email"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  errors={errors.email && touched.email}
                  errorsText={errors.email}
                />
                <div>
                  <ReactSelect
                    className={"p-0 capitalize"}
                    id="type"
                    placeholder="User Role"
                    options={[
                      { value: "preparer", label: "Preparer" },
                      { value: "approver", label: "Approver" },
                    ]}
                    isLoading={false}
                    onChange={(selectedOption) => {
                      setFieldValue("type", selectedOption);
                    }}
                    value={values.type}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          errors.type && touched.type ? "red" : "#cdddeb"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #cdddeb",
                        },
                      }),
                    }}
                  />
                  {errors.type && touched.type ? (
                    <>
                      <InputError errorTitle={errors.type} />
                    </>
                  ) : null}
                </div>
                <div className="mt-6 flex justify-end">
                  <button
                    onClick={() => setAddUserPopup(false)}
                    className="mr-2 px-4 py-2 text-sm font-medium text-gray-800 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-400"
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm font-medium text-white bg-theme rounded-md hover:bg-theme focus:outline-none focus:ring focus:ring-blue-400"
                  >
                    {userData?.id ? "EDIT" : "CREATE"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      {showConfirmation && (
        <ConfirmationModel
          maxWidth={"max-w-72"}
          confirmText={`${userData?.id ? "Edit" : "Create"} User`}
          isLoading={isLoad}
          onClose={handleCancelConfirmation}
          onConfirm={handleConfirmCreateUser}
        />
      )}
    </>
  );
};

export default AddUser;
