import React from "react";
import { headChat } from "../../assets/image";
import { MoreMenu, User } from "../../assets/svg/AllSvg";
import { Link } from "react-router-dom";

const OnGoingService = () => {
  return (
    <div className="space-y-5">
      <div className="flex items-center space-x-5 border-b-2 border-secondary_grey pb-7">
        <img src={headChat} alt="img" />
        <p className="text-4xl font-ebGaramond">
          Your On-Going Service Requests
        </p>
      </div>
      <p className="text-2xl font-ebGaramond">Latest Request</p>
      <div className="mt-4 border border-gray-300 p-4 rounded-lg">
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            {/* status */}
            <p className="font-poppins font-normal text-sm">Service Name 1</p>
          </div>
          <div className="flex gap-1 items-center">
            <p className="font-poppins font-normal text-xs">
              Last Updated on 24/08/2023 | 13:00
            </p>
          </div>
        </div>
        <div className="mt-2 flex">
          <p className="font-poppins font-normal text-sm text-gray-600">
            Current Status:
          </p>
          <span class="text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-xl border border-blue-800 ml-4">
            Pending from Ei Biz
          </span>
        </div>
        <div className="mt-2">
          <p className="font-poppins font-normal text-sm text-themeBlue">
            Request ID: RID-12345869f0
          </p>
          <p className="font-poppins font-normal text-xs text-gray-600">
            Date of Request: 01/01/2024
          </p>
        </div>
        <p className="mt-4 font-poppins font-normal text-sm text-gray-600">
          Handled By:
        </p>
        <div className="mt-1 flex items-center justify-between">
          <div className="gap-2 flex items-center">
            <User />
            <div>
              <p className="font-normal text-gray-500 font-poppins text-sm">
                {" "}
                Ryan
              </p>
            </div>
          </div>
          <div>
            <Link
              to="/on-going-request"
              className="font-normal text-themeBlue underline font-poppins text-sm"
            >
              Open Request
            </Link>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default OnGoingService;
