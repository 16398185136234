import React from "react";
import { LoaderSvg } from "../assets/svg/AllSvg";

const ButtonLoader = () => {
  return (
    <div className="text-white bg-theme font-medium text-sm py-1.5 inline-flex items-center">
      <LoaderSvg />
      <span>Loading...</span>
    </div>
  );
};

export default ButtonLoader;
