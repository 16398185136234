import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackButton, InputWithLabel } from "../../../components/micro";
import { headChat } from "../../../assets/image";
import ReactSelect from "react-select";
import FileUploadChoose from "../../../commons/FileUploadChoose";

const ChangeShareholderParticulars = () => {
  const navigate = useNavigate();
  const [documentType, setDocumentType] = useState("");
  const DocumentTypeOptions = [
    { value: "ic", label: "IC" },
    { value: "passport", label: "Passport" },
  ];

  return (
    <div className="space-y-5">
      <BackButton
        onClick={() => {
          navigate("/service-request");
        }}
      />
      <div className="flex items-center space-x-5 border-b-2 border-secondary_grey pb-7">
        <img src={headChat} alt="img" />
        <div className="space-y-2 font-ebGaramond">
          <p className="text-xl">We see that you will need a request on:</p>
          <p className="text-3xl">Change of Shareholder's Particulars</p>
        </div>
      </div>
      <div className="space-y-3 border-b-2 border-secondary_grey pb-3">
        <p className="text-2xl font-ebGaramond">
          Let us guide u though the process:
        </p>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>1.</span>
          <p>Let us know which Shareholder's Particulars to change</p>
        </div>
        <p className="text-sm">
          Let us know which director’s particular you will like to change
        </p>
        <ReactSelect
          className={"p-0"}
          id="director"
          placeholder="Director"
          options={[{ value: "value", label: "value" }]}
          isLoading={false}
          styles={{
            control: (base) => ({
              ...base,
              border: `1px solid #cdddeb`,
              borderRadius: "0.3rem",
              paddingTop: "2px",
              paddingBottom: "2px",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #cdddeb",
              },
            }),
          }}
        />
        <ReactSelect
          className={"p-0"}
          id="director"
          placeholder="Director"
          options={[{ value: "value", label: "value" }]}
          isLoading={false}
          styles={{
            control: (base) => ({
              ...base,
              border: `1px solid #cdddeb`,
              borderRadius: "0.3rem",
              paddingTop: "2px",
              paddingBottom: "2px",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #cdddeb",
              },
            }),
          }}
        />
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>2.</span>
          <p>Edit the Shareholder's particulars</p>
        </div>
        <InputWithLabel
          className={"border"}
          idFromName={"home_address"}
          type={"text"}
          label={"Home Address"}
        />
        <InputWithLabel
          className={"border"}
          idFromName={"name"}
          type={"text"}
          label={"Name"}
        />
        <ReactSelect
          className={"p-0"}
          id="nationality_citizenship"
          placeholder="Nationality / Citizenship"
          options={[{ value: "value", label: "value" }]}
          isLoading={false}
          styles={{
            control: (base) => ({
              ...base,
              border: `1px solid #cdddeb`,
              borderRadius: "0.3rem",
              paddingTop: "2px",
              paddingBottom: "2px",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #cdddeb",
              },
            }),
          }}
        />
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>3.</span>
          <p>Upload proof of changed particular </p>
        </div>
        <ReactSelect
          className={"p-0"}
          id="documentType"
          placeholder="Document Type"
          options={DocumentTypeOptions}
          isLoading={false}
          value={documentType}
          onChange={(e) => {
            setDocumentType(e);
          }}
          styles={{
            control: (base) => ({
              ...base,
              border: `1px solid #cdddeb`,
              borderRadius: "0.3rem",
              paddingTop: "2px",
              paddingBottom: "2px",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #cdddeb",
              },
            }),
          }}
        />
        {documentType && (
          <div className="space-y-4 border-b-2 border-secondary_grey pb-4">
            <p className="text-sm">
              &#8226; {documentType.label === "IC" ? "IC front" : "Passport"}
            </p>
            <FileUploadChoose />
            {documentType.label === "IC" && (
              <>
                <p className="text-sm">&#8226; IC back</p>
                <FileUploadChoose />
              </>
            )}
          </div>
        )}
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>4.</span>
          <p>Obtain signed resolution</p>
        </div>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>5.</span>
          <p>
            We will lodge the changes to ACRA for the change of Company Name
          </p>
        </div>

        <p className="text-sm">
          Once the request is opened. You can easily manage it over at
          “On-Going” service page.
        </p>
      </div>
      <div className="flex items-center justify-between space-x-1  bg-light_primary_shades rounded-lg px-4 py-3">
        <p className="font-semibold">Total Payable :</p>
        <div>
          <p className="text-xs">1/3 used</p>
          <p>Free</p>
        </div>
      </div>
      <div className="box !rounded-xl flex items-center justify-between">
        <button
          className={`btn-theme uppercase`}
          onClick={() => {
            navigate("/approver");
          }}
        >
          Proceed with request
        </button>
        <div>
          <p className="text-xs">Total:</p>
          <p>Free</p>
        </div>
      </div>
    </div>
  );
};

export default ChangeShareholderParticulars;
