import React, { useEffect, useRef, useState } from "react";
import {
  rightCheck,
  cart,
  rightBlack,
  goBack,
  womanSpeaker,
  Logo,
} from "../../assets/image";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../../components/micro";
import { PackageBreakdown } from "../../assets/svg/AllSvg";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanySubscriptionSummary,
  makePayment,
} from "../../redux/companies/slice";
import ButtonLoader from "../../commons/ButtonLoader";

const PaymentLast = () => {
  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const [activePlan, setActivePlan] = useState(0);
  const [activePlanData, setActivePlanData] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const companyId = location.state.companyId;

  const { companySubscriptionSummaryData, paymentData } = useSelector(
    (store) => ({
      companySubscriptionSummaryData:
        store?.companiesData?.getCompanySubscriptionSummaryStatus?.data?.data,
      paymentData: store?.companiesData?.makePaymentStatus?.data?.data,
    })
  );

  useEffect(() => {
    if (companySubscriptionSummaryData?.plans) {
      setActivePlanData(companySubscriptionSummaryData?.plans[0]);
    }
  }, [companySubscriptionSummaryData]);

  const handleFormSubmit = () => {
    const payload = {
      company_id: companyId,
      sub_total_payable: companySubscriptionSummaryData?.sub_total_payable,
      incorporation_fees: companySubscriptionSummaryData?.incorporation_fees,
      govt_fees: companySubscriptionSummaryData?.govt_fees,
      annual_filing_fees: companySubscriptionSummaryData?.annual_filing_fees,
      shareholders_count: companySubscriptionSummaryData?.shareholders_count,
      corporate_secretary_fees:
        companySubscriptionSummaryData?.corporate_secretary_fees,
      duration:
        activePlanData.title === "Regular"
          ? 1
          : activePlanData.title === "3 Years"
          ? 3
          : 5,
      ...activePlanData,
    };
    setLoading(true);
    dispatch(makePayment(payload)).then((res) => {
      setLoading(false);
      if (res.type === "makePayment/fulfilled") {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }
    });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getCompanySubscriptionSummary(companyId));
  }, [dispatch]);

  return (
    <div className="grid grid-cols-4 gap-5 items-start">
      <div className="col-span-3 card border !p-6 shadow-lg bg-white rounded-lg">
        <BackButton className={"mb-8"} onClick={handleBackClick} />
        <div className="p-2 text-center space-y-2 pb-5 border-b-2 border-secondary_grey mb-10">
          <p className="font-ebGaramond font-normal text-3xl">
            One Last Thing.
          </p>
          <p className="text-base">
            We are offering exclusive discount just for you!
          </p>
        </div>
        <div className="flex justify-center items-center mb-5">
          <p className="text-xl text-center font-ebGaramond max-w-64">
            Enjoy more discount when you subscribe in advance with us.
          </p>
          <div>
            <img src={womanSpeaker} alt="womanSpeaker" className="" />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-5 ">
          {companySubscriptionSummaryData?.plans?.map((e, i) => {
            return (
              <div
                className={`card cursor-pointer !p-7 ${
                  activePlan === i && "bg-secondaryLight"
                } `}
                onClick={() => {
                  setActivePlan(i);
                  setActivePlanData(e);
                }}
              >
                <p className="mb-1">
                  {e?.discount ? `${e?.discount}% Off` : "Regular"}
                </p>
                <div className="flex items-end mb-2">
                  <p className="text-5xl font-ebGaramond font-semibold">
                    ${e?.total}
                  </p>
                  <p className="text-light_secondary_gray text-base">/Year</p>
                </div>
                <p className="text-gray_secondary">{e?.title} Subscription</p>
              </div>
            );
          })}
        </div>
        <p className="text-center text-sm my-5">Terms & Conditions applies*</p>
        {/* button */}
        <div className="flex justify-end">
          <a className="hidden" ref={buttonRef} href={paymentData?.url}>
            Pay Now
          </a>
          <button
            className={
              "bg-theme hover:bg-grey-700 text-white font-bold py-2 px-4 rounded flex"
            }
            onClick={handleFormSubmit}
            disabled={loading}
          >
            {loading ? (
              <ButtonLoader />
            ) : (
              <span className="mr-2 font-poppins font-normal text-sm">
                Continue to check out
              </span>
            )}
            <img className="w-5 h-5" src={cart} />
          </button>
        </div>
      </div>
      <div className="card bg-light_info_shades">
        <div className="flex justify-center py-4">
          <img src={Logo} className="w-12" alt="logo" />
        </div>
        <div className="flex justify-between rounded-xl bg-bgPrimary px-4 py-3 mb-5">
          <p className="font-semibold">Total Payable:</p>
          <p>${Number(activePlanData?.total).toFixed(2)}</p>
        </div>
        <div className="space-y-2">
          <div className="flex justify-between rounded-t-xl border-b border-secondary_grey bg-light_theme px-4 py-3">
            <p>Breakdown</p>
            <PackageBreakdown />
          </div>
          <div className="space-y-2 border-b border-secondary_grey pb-2">
            <div className="flex justify-between">
              <p>Annual Filing Fees</p>
              <div className="text-right">
                <p className="text-gray_secondary text-sm">indication</p>
                <p>
                  $
                  {Number(
                    companySubscriptionSummaryData?.annual_filing_fees
                  ).toFixed(2)}
                </p>
              </div>
            </div>
            <div className="flex justify-between">
              <p>Corporate Secretary Fees</p>
              <div className="text-right">
                <p className="text-gray_secondary text-sm">indication</p>
                <p>
                  $
                  {Number(
                    companySubscriptionSummaryData?.corporate_secretary_fees
                  ).toFixed(2)}
                </p>
              </div>
            </div>
            <div className="flex justify-between">
              <p>Government fees</p>
              <div className="text-right">
                <p className="text-gray_secondary text-sm">indication</p>
                <p>
                  $
                  {Number(companySubscriptionSummaryData?.govt_fees).toFixed(2)}
                </p>
              </div>
            </div>
            <div className="flex justify-between">
              <p>Incorporation Fees</p>
              <div className="text-right">
                <p className="text-gray_secondary text-sm">indication</p>
                <p>
                  $
                  {Number(
                    companySubscriptionSummaryData?.incorporation_fees
                  ).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-between border-b border-secondary_grey pb-2">
            <div>
              <p>Sub Total:</p>
              <p>GST {activePlanData?.gst}%:</p>
            </div>
            <div className="text-right">
              <p>${Number(activePlanData?.sub_total).toFixed(2)}</p>
              <p>${Number(activePlanData?.gst_amount).toFixed(2)}</p>
            </div>
          </div>
          <div className="flex justify-between rounded-b-xl bg-light_theme px-4 py-3">
            <p>Total</p>
            <p className="text-lg">
              ${Number(activePlanData?.total).toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentLast;
