import React, { useState, useRef, useEffect } from "react";
import TextEditor from "../tickets/TextEditor";

const TicketModal = ({ isOpen, onClose }) => {
  const [ticketTitle, setTicketTitle] = useState("");
  const [errors, setErrors] = useState({
    ticketTitle: "",
  });
  const [charCount, setCharCount] = useState(0);
  const [menuOpenIndex, setMenuOpenIndex] = useState(null);
  const [menuOpenPdf, setMenuOpenPdf] = useState(null);

  const modalRef = useRef(null);

  const handleInputChange = (e, ticket) => {
    const { value } = e.target;
    switch (ticket) {
      case "ticketTitle":
        setTicketTitle(value);
        setCharCount(value.length);
        if (value) setErrors((prev) => ({ ...prev, ticketTitle: "" }));
        break;
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setMenuOpenIndex();
      setMenuOpenPdf();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl p-4">
        <div className="items-center pb-3 mb-4">
          <h3 className="text-base font-normal text-themeBlue font-poppins">
            Create New Ticket
          </h3>
          <p className="text-xs font-normal text-gray-500 font-poppins">
            Write and address new queries and issues
          </p>
        </div>

        <div className="mt-4 border-b pb-3 ">
          <div className="relative border field rounded-md">
            <input
              type="text"
              id="ticketTitle"
              name="Ticket"
              className="auth-input error-forms appearance-none focus:outline-none focus:ring-2 focus:border-themeBlue peer h-9 hover:border-themeBlue active:border-themeBlue rounded-md"
              placeholder={"value"}
              value={ticketTitle}
              onChange={(e) => handleInputChange(e, "ticketTitle")}
            />
            <label
              htmlFor="name"
              className={
                "label text-sm peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 text-light_grey focus:text-themeBlue"
              }
            >
              Ticket title
            </label>
          </div>
          <div className="text-light_grey text-xs mt-1 ml-2">
            Max Character ({charCount}/250)
          </div>
        </div>
        <TextEditor isOpen={isOpen}/>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-700 bg-gray-200 hover:bg-gray-300 rounded-md"
          >
            Cancel
          </button>
          <button className="px-4 py-2 text-white bg-theme hover:bg-themeBlue rounded-md">
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketModal;
