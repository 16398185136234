import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ReactSelect from "react-select";
import { InputError } from "../../../commons/MicroComponents";
import { InputWithLabel } from "../../../components/micro";
import { useDispatch, useSelector } from "react-redux";
import {
  addDirectorShareholder,
  existingUsers,
} from "../../../redux/companies/slice";
import { toast } from "react-toastify";

const AddShareholder = ({
  setShowShareholderModel,
  setShareholderArray,
  shareholderArray,
  company_id,
}) => {
  const [existingUsersArray, setExistingUsersArray] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const dispatch = useDispatch();

  // Selector
  const { existingUsersData } = useSelector((store) => ({
    existingUsersData: store?.companiesData?.existingUsersStatus?.data?.data,
  }));

  useEffect(() => {
    dispatch(existingUsers());
  }, []);

  useEffect(() => {
    if (existingUsersData) {
      let data = existingUsersData?.map((x) => ({
        ...x,
        label: x.full_name,
      }));
      setExistingUsersArray(data);
    }
  }, [existingUsersData]);
  return (
    <>
      <div
        className="model"
        onClick={() => {
          setShowShareholderModel(false);
        }}
      >
        <div
          className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-lg min-h-40 py-6 px-6 mx-auto relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="text-2xl font-ebGaramond border-b border-secondary_grey w-full pb-3 mb-4">
            Add Shareholder
          </h2>
          <Formik
            enableReinitialize={true}
            initialValues={{
              shareholder_type: "",
              citizenship: "",
              existing_contact: "",
              full_name: "",
              email: "",
            }}
            validationSchema={Yup.object().shape({
              shareholder_type: Yup.object().required(
                "Please Select Type of Shareholder"
              ),
              citizenship: Yup.object().required("Please Select Citizenship"),
              existing_contact: Yup.object(),
              full_name: Yup.string().required("Please Enter Full Name"),
              email: Yup.string().required("Please Enter Email"),
            })}
            onSubmit={(values) => {
              const payload = {
                existing_contact: values?.existing_contact?.id,
                full_name: values?.full_name,
                email: values?.email,
                shareholder_type: values?.shareholder_type?.value,
                citizenship: values?.citizenship?.value,
                company_id: company_id,
                main_signee: false,
                type: "shareholder",
              };
              setIsLoad(true);
              dispatch(addDirectorShareholder(payload)).then((res) => {
                setIsLoad(false);
                if (res.type === "addDirectorShareholder/fulfilled") {
                  setShareholderArray([...shareholderArray, res.payload.data]);
                  toast.success(res.payload.message);
                  setShowShareholderModel(false);
                }
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="w-full space-y-3" onSubmit={handleSubmit}>
                {/* Type of Shareholder */}
                <div>
                  <ReactSelect
                    className={"text-black p-0"}
                    id="shareholder_type"
                    placeholder="Type of Shareholder"
                    options={[
                      { label: "Individual Shareholder", value: "individual" },
                      { label: "Corporate Shareholder", value: "corporate" },
                    ]}
                    isLoading={false}
                    getOptionValue={(shareholder_type) =>
                      shareholder_type.value
                    }
                    value={values.shareholder_type}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("shareholder_type", e);
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          errors.shareholder_type && touched.shareholder_type
                            ? "red"
                            : "#0000003B"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    }}
                  />
                  {errors.shareholder_type && touched.shareholder_type ? (
                    <InputError errorTitle={errors.shareholder_type} />
                  ) : null}
                </div>
                {/* Citizenship */}
                <div>
                  <ReactSelect
                    className={"text-black p-0"}
                    id="citizenship"
                    placeholder="Citizenship"
                    options={[
                      { label: "Singaporean", value: "singaporean" },
                      {
                        label: "Permanent Resident",
                        value: "permanent_resident",
                      },
                      { label: "Foreigners", value: "foreigners" },
                    ]}
                    isLoading={false}
                    getOptionValue={(citizenship) => citizenship.value}
                    value={values.citizenship}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("citizenship", e);
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          errors.citizenship && touched.citizenship
                            ? "red"
                            : "#0000003B"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    }}
                  />
                  {errors.citizenship && touched.citizenship ? (
                    <InputError errorTitle={errors.citizenship} />
                  ) : null}
                </div>
                {/* Existing Contact */}
                {!!existingUsersData?.length && (
                  <div>
                    <ReactSelect
                      className={"text-black p-0"}
                      id="existing_contact"
                      placeholder="Existing Contact"
                      options={existingUsersArray}
                      isLoading={false}
                      getOptionValue={(existing_contact) =>
                        existing_contact.value
                      }
                      value={values.existing_contact}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("existing_contact", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.existing_contact && touched.existing_contact
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    {errors.existing_contact && touched.existing_contact ? (
                      <InputError errorTitle={errors.existing_contact} />
                    ) : null}
                  </div>
                )}
                <InputWithLabel
                  label={"Full Name"}
                  type={"text"}
                  className={
                    errors.full_name && touched.full_name
                      ? ` input-error `
                      : ` border `
                  }
                  idFromName="full_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.full_name}
                  errors={errors.full_name && touched.full_name}
                  errorsText={errors.full_name}
                />
                <InputWithLabel
                  label={"Email"}
                  type={"email"}
                  className={
                    errors.email && touched.email ? ` input-error ` : ` border `
                  }
                  idFromName="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  errors={errors.email && touched.email}
                  errorsText={errors.email}
                />
                <div className="flex justify-end gap-4 w-full">
                  <button
                    type="button"
                    className="btn-outline uppercase"
                    onClick={() => {
                      setShowShareholderModel(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isLoad}
                    className={`${
                      isLoad ? "btn-outline-disabled" : "btn-theme"
                    } uppercase`}
                  >
                    {isLoad ? "Loading" : "Add"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddShareholder;
