import React, { useState } from "react";
import { BackButton, InputWithLabel } from "../../../components/micro";
import { headChat } from "../../../assets/image";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import FileUploadChoose from "../../../commons/FileUploadChoose";

const ApplyingForPasses = () => {
  const [passType, setPassType] = useState("");
  const PassTypeOptions = [
    { value: "employmentPass", label: "Employment Pass" },
    { value: "dPass", label: "D Pass" },
  ];
  const navigate = useNavigate();

  return (
    <div className="space-y-5">
      <BackButton
        onClick={() => {
          navigate("/service-request");
        }}
      />
      <div className="flex items-center space-x-5 border-b-2 border-secondary_grey pb-7">
        <img src={headChat} alt="img" />
        <div className="space-y-2 font-ebGaramond">
          <p className="text-xl">We see that you will need a request on:</p>
          <p className="text-3xl">Applying for passes</p>
        </div>
      </div>
      <div className="space-y-3 border-b-2 border-secondary_grey pb-3">
        <p className="text-2xl font-ebGaramond">
          Let us know what type of passes you need
        </p>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>1.</span>
          <p>How many new local director are you appointing?</p>
        </div>
        <p className="text-sm">
          Select the type of pass you need us to handle and process
        </p>
        <ReactSelect
          className={"p-0"}
          id="passType"
          placeholder="Type of Pass"
          options={PassTypeOptions}
          isLoading={false}
          value={passType}
          onChange={(e) => {
            setPassType(e);
          }}
          styles={{
            control: (base) => ({
              ...base,
              border: `1px solid #cdddeb`,
              borderRadius: "0.3rem",
              paddingTop: "2px",
              paddingBottom: "2px",
              boxShadow: "none",
              "&:hover": {
                border: "1px solid #cdddeb",
              },
            }),
          }}
        />
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>2.</span>
          <p>Provide us with the hire particulars</p>
        </div>
        {passType && (
          <>
            <p className="text-sm">i. IC</p>
            <li className="text-sm">IC front</li>
            <FileUploadChoose />
            <li className="text-sm">IC back</li>
            <FileUploadChoose />
            <p className="text-sm">ii. Passport</p>
            <FileUploadChoose />
            <p className="text-sm">iii. Resume</p>
            <FileUploadChoose />
            <p className="text-sm">iv. Education Certificate</p>
            <FileUploadChoose />
          </>
        )}

        {passType?.value === "dPass" && (
          <>
            <p className="text-sm">v. Marriage Certificate/birth certificate</p>
            <FileUploadChoose />
          </>
        )}

        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>3.</span>
          <p>Obtain required documents if needed</p>
        </div>
        <div className="flex space-x-1 text-theme bg-light_primary_shades rounded-lg px-4 py-3">
          <span>4.</span>
          <p>We will submit it to the MOM for final approval</p>
        </div>
        <p className="text-sm">
          Once the request is opened. You can easily manage it over at
          “On-Going” service page.
        </p>
      </div>
      <div className="flex items-center justify-between space-x-1  bg-light_primary_shades rounded-lg px-4 py-3">
        <p className="font-semibold">Total Payable :</p>
        <div>
          <p className="text-xs">Total</p>
          {passType?.value === "employmentPass" ? (
            <p>$6,360</p>
          ) : passType?.value === "dPass" ? (
            <p>$860</p>
          ) : (
            <p>Conditional</p>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between space-x-1 rounded-lg px-4 py-3 shadow-lg border">
        <button
          className={`${passType ? "btn-theme" : "btn-disabled"}`}
          onClick={() => {
            navigate("/on-going-service");
          }}
        >
          Proceed with request
        </button>
        <div>
          <p className="text-xs">Amount:</p>
          {passType?.value === "employmentPass" ? (
            <p>$6,360</p>
          ) : passType?.value === "dPass" ? (
            <p>$860</p>
          ) : (
            <p>Conditional</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default ApplyingForPasses;
