import React from "react";
import { Logo, success } from "../../assets/image";

export default function VerificationEmail() {
  return (
    <div>
      <div className="absolute top-3 left-6 w-20 h-20">
        <img src={Logo} alt="logo" className="w-full h-full object-cover" />
      </div>
      <div className="relative top-52">
        <div className="flex justify-center items-center h-24">
          <p className="text-3xl font-ebGaramond font-normal text-center">
            {" "}
            Check your email for the <br /> verification Email!
          </p>
        </div>
        <div className="sm:flex lg:flex xl:flex justify-center">
          <img
            src={success}
            alt="logo"
            className="sm:max-w-fit lg:max-w-screen-sm"
          />
        </div>
      </div>
    </div>
  );
}
